<template>
  <div>
    <b-form-group class="custom__form--input mb-0">
      <label for="date-effective">Masukan Nominal <span class="text-danger">*</span></label>
      <input :id="`amount-tunai-${indexNominal}`" class="focus-0 custom__input pl-1 w-100" type="text"
        :value="formPayload.cash_total_payment" @input="getAmountTunai" @change="getAmountTunai" />
    </b-form-group>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import {
  BContainer,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from "bootstrap-vue";
import { formatRupiah } from "@/auth/utils";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  props: {
    isSubmit: {
      type: Boolean,
    },
    isButtonHide: {
      type: Boolean,
      default: false,
    },
    submit: {
      type: Function,
    },
    column: {
      type: Number,
      default: 5,
    },
    formData: {
      type: Object,
    },
    totalNominal: {
      type: Number,
    },
    totalAddtionalCost: {
      type: Number,
    },
    indexNominal: {
      type: Number,
    },
  },
  setup() {
    return {
      formatRupiah,
    };
  },
  data() {
    return {
      formPayload: {
        cash_total_payment: "",
      },
    };
  },
  watch: {
    formData: {
      handler(value) {
        this.formPayload = value
        setTimeout(() => {
          this.getAmountTunai()
        }, 100);
      },
      deep: true,
    },
    // localStorage.payment_customer_uuid
    formPayload: {
      handler(value) {
        const payload = {
          ...value,
          payment_method: "tunai",
        };
        this.$emit("payloadTunai", payload);
      },
      deep: true,
    },
  },
  created() { },
  mounted() {

    const transaction_query = JSON.parse(
      localStorage.getItem("transaction_query")
    );

    if (transaction_query.data) {
      const decodeData = transaction_query.data
      setTimeout(() => {
        for (let index = 0; index < decodeData.length; index++) {
          const element = decodeData[index];
          if (
            this.indexNominal === index &&
            element.payment_method == "tunai"
          ) {
            this.formPayload = {
              payment_method: element.payment_method,
              cash_total_payment: element.cash_total_payment,
            };
          }
        }
      }, 500);
    }

    // Re assign cash total payment on same index
    this.formPayload.cash_total_payment = this.formData.cash_total_payment

    // if (transaction_query.total) {
    //   setTimeout(() => {
    //     this.formPayload.cash_total_payment = transaction_query.total || '';
    //     setTimeout(() => {
    //       this.getAmountTunai();
    //     }, 200);
    //   }, 300);
    // }
  },
  methods: {
    formatCurrency(value) {
      if (isNaN(value)) {
        return "";
      }
      return value.toLocaleString("id-ID");
    },
    getAmountTunai() {
      const rupiah = document.getElementById(
        `amount-tunai-${this.indexNominal}`
      );
      rupiah.value = this.formatRupiah(rupiah.value, "");
      this.formPayload.cash_total_payment = rupiah.value;
      setTimeout(() => {
        if (document.getElementById(`nominal--${this.indexNominal}`)) {
          document.getElementById(
            `nominal--${this.indexNominal}`
          ).innerHTML = `Rp ${!rupiah.value.match(".") ? rupiah.value : rupiah.value
          }`;
        }
      }, 300);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";

.bg-danger {
  background-color: $danger-1 !important;
  border: 1px solid $danger-2 !important;
  color: $dark !important;
  border-radius: 8px;
}
</style>
